// gallery_slider_controller.js
import { Controller } from 'stimulus'
import Swiper, { Pagination } from 'swiper'

Swiper.use([Pagination])

export default class extends Controller {
  static targets = ['bg']

  connect() {
    this.initGallerySlider()
    document.addEventListener("DOMContentLoaded", this.lazyLoadBG.bind(this))
  }

  initGallerySlider() {
    new Swiper('.js-gallerySlider', {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoHeight: true,
    })
  }

  lazyLoadBG() {
    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            entry.target.style.backgroundImage = `url('${entry.target.dataset.image}')`
            lazyBackgroundObserver.unobserve(entry.target)
          }
        });
      });
      this.bgTargets.forEach(function(lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground)
      })
    }
  }
}
